@import url('https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth
}
body {
  margin: 0;
  font-family: "Lora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  @apply bg-cyan-900
  
}

.bgColor {
  background-color: #e7e2dd;
}

.bgColor2 {
  background-color: rgb(41, 48, 46)
}

.bgColorOnMd {
  background-color: none;
}